<script>
export default {
  components: {  },
  beforeCreate: function() {
    document.body.className = 'donators';
  },
  data() {
    return {
    };
  },
  created: () => {
  },
  methods: {
  }
};
</script>

<template>
  <div id="layout-wrapper">
  <header id="page-topbar">
    <div class="navbar-header">
      <div class="d-flex">
      </div>

      <div class="d-flex">
        <router-link tag="a" to="/" class="logo">
          <img height="40px" src="@/assets/images/logo.png">
        </router-link>
      </div>

      <div class="d-flex">
      </div>
    </div>
  </header>
    <div class="main-content ml-0">
      <div class="page-content px-0">
        <div class="container-fluid p-0">
          <slot />
        </div>
      </div>
    </div>
  </div>
</template>
